import React, { useState, useEffect, createContext } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import "../index.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { setGlobalState, useGlobalState } from "../state";
import { getPhoneScores } from "../httpServices/services";
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import DrawerCustom from '../component/Drawer';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ContactlessOutlined } from "@mui/icons-material";
import EditButtons from './EditButtons'

export default function Configuration(props) {
  const [lowScore, setLowScore] = useGlobalState('lowScore');
  const [medScore, setMedScore] = useGlobalState('medScore');
  const [highScore, setHighScore] = useGlobalState('highScore');
  const [maxPriority_Low, setMaxPriority_Low] = useGlobalState('maxPriority_Low');
  const [initialPriority_Low, setInitialPriority_Low] = useGlobalState('initialPriority_Low');
  const [initialPriority_Medium, setInitialPriority_Medium] = useGlobalState('initialPriority_Medium');
  const [waitTimeThreshMin, setWaitTimeThreshMin] = useGlobalState('waitTimeThreshMin');
  const [originalLowScore, setOriginalLowScore] = useGlobalState("originalLowScore");
  const [cafGlobalEnable, setCafGlobalEnable] = useGlobalState('cafGlobalEnable');
  const [maxCallHoldingLower, setMaxCallHoldingLower] = useGlobalState('maxCallHoldingLower');
  const [maxCallHoldingUpper, setMaxCallHoldingUpper] = useGlobalState('maxCallHoldingUpper');
  const [maxWaitLow, setMaxWaitLow] = useGlobalState('maxWaitLow');
  const [maxWaitUpper, setMaxWaitUpper] = useGlobalState('maxWaitUpper');
  const [enableCafRouting, setEnableCafRouting] = ('');
  const [open, setOpen] = React.useState(false);
  const [midScoreGreatErr, setMidScoreGreatErr] = React.useState(false);
  const [highScoreLowErr, setHighScoreLowErr] = React.useState(false);
  const [midScoreLowErr, setMidScoreLowErr] = React.useState(false);
  const [successError, setSuccessError] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const [maxPriErr, setMaxPriErr] = React.useState(false);
  const [zeroError, setZeroError] = React.useState(false);
  const [unoSliderDisable, setUnoSliderDisable] = React.useState();
  const [taskHighThreshold, setTaskHighThreshold] = useGlobalState('taskHighThreshold');
  const [taskLowThreshold, setTaskLowThreshold] = useGlobalState('taskLowThreshold');
  const [webLeadHighThreshold, setWebLeadHighThreshold] = useGlobalState('webLeadHighThreshold');
  const [webLeadLowThreshold, setWebLeadLowThreshold] = useGlobalState('webLeadLowThreshold');
  const [disableSave, setDisableSave] = useGlobalState('disableSave');
  const [workFlowData, setWorkFlowData] = useGlobalState('workFlowData');
  const [workFlowDataBody, setWorkFlowDataBody] = useGlobalState('workFlowDataBody');
  const [waitTimebody, setWaitTimebody] = useGlobalState('waitTimebody');
  const [phone_score_array, set_phone_score_array] = useState([])
  const [initial_priority_array, set_initial_priority_array] = useState([])
  const [max_priority_array, set_max_priority_array] = useState([])
  const [wait_time_array, set_wait_time_array] = useState([])
  const [uno_array, set_uno_array] = useState([])
  const [editMode, setEditMode] = useGlobalState('editMode')
  const [configUpdateBody, setConfigUpdateBody] = useGlobalState('configUpdateBody')
  const [imConfigData, setImConfigData] = useGlobalState('imConfigData')
  const [midScoreThresholdTask, setMidScoreThresholdTask] = useGlobalState('midScoreThresholdTask')
  const [midScoreThresholdWebLead, setMidScoreThresholdWebLead] = useGlobalState('midScoreThresholdWebLead')
  const [unoPercentage, setUnoPercentage] = useGlobalState('unoPercentage');
  const [ruleIndex, setRuleIndex] = useGlobalState('ruleIndex')
  const [attributeIndex, setAttributeIndex] = useGlobalState('attributeIndex')
  const [phoneScoreBody, setPhoneScoreBody] = useGlobalState('phoneScoreBody')
  const [newWaitTimeBody, setNewWaitTimeBody] = useGlobalState('newWaitTimeBody')
  const [unoBody, setUnoBody] = useGlobalState('unoBody')
  const [percentThresholdBody, setPercentThresholdBody] = useGlobalState('percentThresholdBody')


  //Manual Input Change Functions for Sliders
  const handleLowScoreInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Low"] = lowScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.low_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setLowScore(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleMedScoreInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Medium"] = medScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.medium_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setMedScore(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleHighScoreInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_High"] = highScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.high_threshold"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setHighScore(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };

  const handleInitialPriLowInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Low"] = initialPriority_Low
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.low.initial_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Low(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInitialPriMedInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Medium"] = initialPriority_Medium
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.medium.initial_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Medium(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleMaxPriorityInputChange = (event, newValue) => {
    phoneScoreBody.originalValues["MaxPriority_Low"] = maxPriority_Low
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.low.max_priority"] = event.target.value
    phoneScoreBody.updateFlag = true;
    setMaxPriority_Low(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleWaitTimeInputChange = (event, newValue) => {
    newWaitTimeBody.originalValues["WaitTimeThresholdMinutes"] = waitTimeThreshMin
    newWaitTimeBody.newValues["data.department.sales.wait_time_threshold_minutes"] = event.target.value
    newWaitTimeBody.updateFlag = true;
    setWaitTimeThreshMin(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleUnoPercentageInputChange = (event) => {
    percentThresholdBody.originalValues["ProjectUnoPercentageThreshold"] = unoPercentage;
    let keyString = "data.rules." + ruleIndex + ".attributes." + attributeIndex + ".val"
    percentThresholdBody.newValues[keyString] = event.target.value
    percentThresholdBody.updateFlag = true;
    setUnoPercentage(event.target.value === '' ? '' : Number(event.target.value));
    let result = event.target.value.toString()
    if (result.substring(0,1) != "0"){
      setZeroError(true);
      setDisableSave(true);
    } else if (result.substring(0,1) == "0"){
      setZeroError(false);
      setDisableSave(false);
    } 
  };




  const lowScoreArray = []
  //Change functions for Sliders
  const handleLowScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Low"] = lowScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.low_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setLowScore(newValue);
  };

  const handleMedScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_Medium"] = medScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.medium_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setMedScore(newValue);
  };

  const handleHighScoreSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["ProgressionScore_High"] = highScore
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.team_assignment_scoring.high_threshold"] = newValue
    phoneScoreBody.updateFlag = true;
    setHighScore(newValue);
  };

  const handleInitialPriLowSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Low"] = initialPriority_Low
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.low.initial_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Low(newValue);
  };

  const handleInitialPriMedSliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["InitialPriority_Medium"] = initialPriority_Medium
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.medium.initial_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setInitialPriority_Medium(newValue);
  };

  const handleMaxPrioritySliderChange = (event, newValue) => {
    phoneScoreBody.originalValues["MaxPriority_Low"] = maxPriority_Low
    phoneScoreBody.newValues["data.cec_sales_phone_scoring.priority_scoring.groups.low.max_priority"] = newValue
    phoneScoreBody.updateFlag = true;
    setMaxPriority_Low(newValue);
  };

  const handleRollWaitTimeSliderChange = (event, newValue) => {
    newWaitTimeBody.originalValues["WaitTimeThresholdMinutes"] = waitTimeThreshMin
    newWaitTimeBody.newValues["data.department.sales.wait_time_threshold_minutes"] = newValue
    setWaitTimeThreshMin(newValue);
    newWaitTimeBody.updateFlag = true;
  };

  const handleEnableChange = (event) => {
    unoBody.originalValues["GlobalEnable"] = cafGlobalEnable;
    unoBody.newValues["toggle.global_high_po_routing_enabled.enabled"] = !cafGlobalEnable
    unoBody.updateFlag = true;
    setCafGlobalEnable(event.target.checked);
  };
  const handleMaxCallHoldingUpperChange = (event, newValue) => {
    unoBody.originalValues["maxCallHoldingUpper"] = maxCallHoldingUpper;
    unoBody.newValues["data.task.high_score_threshold"] = newValue
    unoBody.newValues["data.web_lead.high_score_threshold"] = newValue
    unoBody.updateFlag = true;
    setMaxCallHoldingUpper(newValue);
  };
  const handlemaxCallHoldingLowerChange = (event, newValue) => {
    setMaxCallHoldingLower(newValue);
    setTaskLowThreshold(newValue);
    setWebLeadLowThreshold(newValue);
  };
  const handleMidScoreThresholdChange = (event, newValue) => {
    unoBody.originalValues["midScoreThresholdTask"] = midScoreThresholdTask
    unoBody.newValues["data.task.mid_score_threshold"] = newValue
    unoBody.newValues["data.web_lead.mid_score_threshold"] = newValue
    unoBody.updateFlag = true;
    setMidScoreThresholdTask(newValue);
    setMidScoreThresholdWebLead(newValue);
  };

  const handleUnoPercentageChange = (event, newValue) => {
    percentThresholdBody.originalValues["ProjectUnoPercentageThreshold"] = unoPercentage;
    let keyString = "data.rules." + ruleIndex + ".attributes." + attributeIndex + ".val"
    percentThresholdBody.newValues[keyString] = newValue
    percentThresholdBody.updateFlag = true;
    setUnoPercentage(newValue)
  };

  const getImConfigData = async () => {
    let params = { name: "all" }
    let result = await axios.get('/api/az_func_im_config', { params });
    sessionStorage.setItem("ImConfigData", JSON.stringify(result.data))
    let data = sessionStorage.getItem("ImConfigData")
    setGlobalState("imConfigData", JSON.parse(data))
    if (result.data === null || result.data === "") {
      result = { data: "No record found with that name" }
    }
    return result
  }


  const setValues = async () => {
    let result = await getImConfigData()
    for (const i in result.data) {
      if (result.data[i].name == "inbound_voice_general") {
        setLowScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.low_threshold)
        setMedScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.medium_threshold)
        setHighScore(result.data[i].data.cec_sales_phone_scoring.team_assignment_scoring.high_threshold)
        setInitialPriority_Low(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.initial_priority)
        setInitialPriority_Medium(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.medium.initial_priority)
        setMaxPriority_Low(result.data[i].data.cec_sales_phone_scoring.priority_scoring.groups.low.max_priority)
      } else if (result.data[i].name == "inbound_voice_rollover") {
        setWaitTimeThreshMin(result.data[i].data.department.sales.wait_time_threshold_minutes);
      }
    }
  }

  const setUnoValues = async () => {
    let result = await getImConfigData()
    for (const i in result.data) {
      if (result.data[i].name == "work_item_general") {
        setCafGlobalEnable(result.data[i].toggle.global_high_po_routing_enabled.enabled)
        setMaxCallHoldingLower(result.data[i].data.task.low_score_threshold)
        setMaxCallHoldingUpper(result.data[i].data.task.high_score_threshold)
        setMidScoreThresholdTask(result.data[i].data.task.mid_score_threshold)
        setMidScoreThresholdWebLead(result.data[i].data.web_lead.mid_score_threshold)
      }
    }
    let params = { name: "work_item_routing_override" }
    let percentResult = await axios.get('/api/az_func_im_config', { params });
    let response = {
      ab_test_current_percentage: null,
      rule_index: null,
      attribute_index: null
    }
    let rules = percentResult.data.data.rules
    let attributes = []
    for (let i in rules) {
      try {
        if (rules[i].cct_web_app_rule === 'project_uno') {
          response.rule_index = i
          setRuleIndex(i)
          attributes = rules[i].attributes
          break;
        }
      } catch (error) { }
    }
    for (let i in attributes) {
      try {
        if (attributes[i].cct_web_app_flag === "active") {
          setUnoPercentage(attributes[i].val)
          setAttributeIndex(i)
        }
      } catch (error) { }
    }
    unoEdits();
  }

  useEffect(() => {
    unoEdits()
  });

  useEffect(() => {
    setValues()
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setHighScoreLowErr(false);
    setMidScoreGreatErr(false);
    setMidScoreLowErr(false)
    setMaxPriErr(false)
    setZeroError(false)
  };



  const wfmAlerts = async () => {

    if (maxCallHoldingUpper < maxCallHoldingLower) {
      setOpen(true);
      setDisableSave(true)
    } else if (maxCallHoldingUpper > maxCallHoldingLower) {
      setOpen(false);
      setDisableSave(false)
    }

    if (midScoreThresholdTask > maxCallHoldingUpper) {
      setMidScoreGreatErr(true)
      setDisableSave(true)
    } else if (maxCallHoldingUpper < midScoreThresholdTask) {
      setHighScoreLowErr(true)
      setDisableSave(true)
    }

    if (midScoreThresholdTask < maxCallHoldingLower) {
      setMidScoreLowErr(true)
      setDisableSave(true)
    }

    if (maxPriority_Low < initialPriority_Low) {
      setMaxPriErr(true)
      setDisableSave(true)
    }
  }

  const unoEdits = () => {
    if (editMode && cafGlobalEnable) {
      setUnoSliderDisable(false);
    } else {
      setUnoSliderDisable(true);
    }
  }

  const handleLowBlur = () => {
    if (lowScore < 0) {
      setLowScore(0);
    } else if (lowScore > 1) {
      setLowScore(1);
    }
  };

  const handleMedBlur = () => {
    if (medScore < 0) {
      setMedScore(0);
    } else if (medScore > 1) {
      setMedScore(1);
    }
  };

  const handleHighBlur = () => {
    if (highScore < 0) {
      setHighScore(0);
    } else if (highScore > 1) {
      setHighScore(1);
    }
  };




  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DrawerCustom />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <EditButtons sx={{ marginTop: '-3%' }} />
        <Toolbar />

        {/* //New Code */}
        <Typography className="uiHeaderFont" variant="h3" sx={{ marginTop: -15 }}>WFM Configurations</Typography>
        <Divider sx={{ marginBottom: 2, width: '55%' }} />
        <Typography className="uiSubHeaderFont" sx={{ fontSize: 18, marginBottom: 2, width: '85%' }}>
          These are configurations related to phone scoring for CEC Sales which determines which team a call is assigned to - core selling or inbound work - and if their priority is High/Medium/Low.{" "}
        </Typography>
        <Typography variant="h5">Team Assignment Scoring</Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {/* <Typography sx={{ fontSize: 13, marginBottom: 2 }}>
          Call center call scoring is the process of evaluating call data
          against defined performance standards. Being used by call
          center managers to analyze live as well as recorded calls, call
          center call scoring uses evaluation forms for scoring customer
          service agents.{" "}
        </Typography> */}
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={setValues} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Team Assignment Scores
            </Typography>
            <Tooltip
              title="These configs determine if the caller is high/medium/low priority group, and then sets the priority values for the call"
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} spacing={5}>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Team Assignment Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score Low: <Input
                    value= {lowScore}
                    size="small"
                    onChange={handleLowScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: 0.001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                      fontColor: 'red'
                    }}
                  />
                </Typography>
                <Slider
                  value={lowScore}
                  onChange={handleLowScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={lowScore}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score Medium: <Input
                    value={medScore}
                    size="small"
                    onChange={handleMedScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: .001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={medScore}
                  onChange={handleMedScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={medScore}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Team Assignment Score High: <Input
                    value={highScore}
                    size="small"
                    onChange={handleHighScoreInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: .001,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={highScore}
                  onChange={handleHighScoreSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={highScore}
                  disabled={!editMode}
                  step={.001}
                  min={0}
                  max={1}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Initial Priority Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                Initial Priority Low: <Input
                  value={initialPriority_Low}
                  size="small"
                  onChange={handleInitialPriLowInputChange}
                  onClick={wfmAlerts}
                  disabled={!editMode}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}
                />
                <Slider
                  value={initialPriority_Low}
                  onChange={handleInitialPriLowSliderChange}
                  onClick={wfmAlerts}
                  aria-labelledby="input-slider"
                  defaultValue={initialPriority_Low}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
                <Typography id="input-slider" gutterBottom>
                  Initial Priority Medium: <Input
                    value={initialPriority_Medium}
                    size="small"
                    onChange={handleInitialPriMedInputChange}
                    disabled={!editMode}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>
                <Slider
                  value={initialPriority_Medium}
                  onChange={handleInitialPriMedSliderChange}
                  aria-labelledby="input-slider"
                  defaultValue={initialPriority_Medium}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Max Priority Scores
                </Typography>
                <Divider sx={{ width: '70%', marginBottom: 2 }} />
                <Typography id="input-slider" gutterBottom>
                  Max Priority Low: <Input
                    value={maxPriority_Low}
                    size="small"
                    onChange={handleMaxPriorityInputChange}
                    onClick={wfmAlerts}
                    disabled={!editMode}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Typography>

                <Slider
                  value={maxPriority_Low}
                  onChange={handleMaxPrioritySliderChange}
                  onClick={wfmAlerts}
                  aria-labelledby="input-slider"
                  defaultValue={maxPriority_Low}
                  disabled={!editMode}
                  step={1}
                  min={1}
                  max={100}
                  size="small"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Typography variant="h5">Rollover Wait Time</Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              Rollover Wait Time
            </Typography>
            <Tooltip
              title="Configurations that determine rollover wait times for inbound voice queues"
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails sx={{ width: "100%" }}>
            <Typography>
              Wait Time Threshold Minutes:<Input
                value={waitTimeThreshMin}
                size="small"
                onChange={handleWaitTimeInputChange}
                disabled={!editMode}
                inputProps={{
                  step: 5,
                  min: 0,
                  max: 60,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Typography>
            <Slider
              value={waitTimeThreshMin}
              onChange={handleRollWaitTimeSliderChange}
              aria-labelledby="input-slider"
              defaultValue={waitTimeThreshMin}
              disabled={!editMode}
              step={5}
              min={0}
              max={60}
            />
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" sx={{ marginTop: 2 }}>
          Project Uno
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Accordion sx={{ width: "100%" }}>
          <AccordionSummary
            onClick={setUnoValues}
            expandIcon={
              <ExpandMoreIcon onClick={setUnoValues} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="uiSubHeaderFont" id="accordionLabel">
              High Value Routing
            </Typography>
            <Tooltip
              title="Global configs to modify Project Uno status."
              sx={{ paddingTop: "0", color: '#febd12' }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs>
              <Typography variant="h6" sx={{ marginRight: 1 }} >Global Configurations</Typography>
              <Typography sx={{ marginRight: 1 }} >Global Enable </Typography>
              <Switch checked={cafGlobalEnable} disabled={!editMode} onChange={handleEnableChange} inputProps={{ 'aria-label': 'controlled' }} />
              <Typography sx={{ marginRight: 1 }} >Percentage Threshold: <Input
                value={unoPercentage}
                size="small"
                onChange={handleUnoPercentageInputChange}
                disabled={unoSliderDisable}
                inputProps={{
                  step: .001,
                  min: 0,
                  max: 1,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              /></Typography>
              <Slider
                value={unoPercentage}
                onChange={handleUnoPercentageChange}
                aria-labelledby="input-slider"
                defaultValue={unoPercentage}
                disabled={unoSliderDisable}
                step={.001}
                min={0}
                max={1}
              />
              <Typography sx={{ marginRight: 1 }} >High Score Threshold: {maxCallHoldingUpper}</Typography>
              <Slider
                value={maxCallHoldingUpper}
                onChange={handleMaxCallHoldingUpperChange}
                onClick={wfmAlerts}
                aria-labelledby="input-slider"
                defaultValue={maxCallHoldingUpper}
                disabled={unoSliderDisable}
                step={.001}
                min={0}
                max={1}
              />
              <Typography sx={{ marginRight: 1 }} >Mid Score Threshold: {midScoreThresholdTask} </Typography>
              <Slider
                value={midScoreThresholdTask}
                onChange={handleMidScoreThresholdChange}
                onClick={wfmAlerts}
                aria-labelledby="input-slider"
                defaultValue={midScoreThresholdTask}
                disabled={unoSliderDisable}
                step={.001}
                min={0}
                max={1}
              />
              <Typography sx={{ marginRight: 1 }} >Low Score Threshold: {maxCallHoldingLower} </Typography>
              <Slider
                value={maxCallHoldingLower}
                onChange={handlemaxCallHoldingLowerChange}
                onClick={wfmAlerts}
                aria-labelledby="input-slider"
                defaultValue={maxCallHoldingLower}
                disabled
                step={.001}
                min={0}
                max={1}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* //New Code */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Global High Configuration CAN NOT be lower than Global Low Configuration
          </Alert>
        </Snackbar>
        <Snackbar open={midScoreGreatErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Mid Score Threshold CAN NOT be GREATER than High Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={highScoreLowErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            High Score Threshold CAN NOT be LOWER than High Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={midScoreLowErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Mid Score Threshold CAN NOT be LOWER than Low Score Threshold
          </Alert>
        </Snackbar>
        <Snackbar open={maxPriErr} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Max Priority Low CAN NOT be lower than Initial Priority Low
          </Alert>
        </Snackbar>
        <Snackbar open={zeroError} autoHideDuration={6000} onClose={handleClose} sx={{ width: '97%' }}>
          <Alert onClose={handleClose} variant="filled" severity="error" sx={{ width: '100%' }}>
            Manual Inputs must start with ZERO
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
